export const isEmail = (email: string): boolean => {
  const expression: RegExp =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
  return expression.test(email)
}

export const camelToSentence = (input: string): string => {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
    .toLowerCase() // Convert everything to lowercase
    .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
}
