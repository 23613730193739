import ReactLoading from 'react-loading'

interface LoadingIndicatorProps {
  color?: string
  width?: string
  height?: string
}

const LoadingIndicator = ({ color, width, height }: LoadingIndicatorProps) => (
  <ReactLoading
    className="top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
    type={'spinningBubbles'}
    color={color ? color : '#eeeeee'}
    height={height ? height : '64px'}
    width={width ? width : '64px'}
  />
)

export default LoadingIndicator
