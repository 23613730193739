import ReactLoading from 'react-loading'

interface LoadingIndicatorProps {
  color?: string
  width?: string
  height?: string
}

const ButtonLoadingIndicator = ({ color, width, height }: LoadingIndicatorProps) => (
  <div className="flex flex-row justify-center items-center">
    <ReactLoading
      type={'spinningBubbles'}
      color={color ? color : '#eeeeee'}
      height={height ? height : '20px'}
      width={width ? width : '20px'}
    />
  </div>
)

export default ButtonLoadingIndicator
