import React, { useEffect, useState } from 'react'
import Svg from './Svg'
import { Link, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useDataContext } from '../context/DataProvider'
import Path, { Menu, MenuItem, MenuForAdmin, MenuForUser } from '../routes/Path'
import { useAuth } from '../context/AuthProvider'

export default function Sidebar() {
  const location = useLocation()
  const { clients } = useDataContext()
  const { isMixedAdmin, isReadOnlyUser } = useAuth()
  const { pathname } = location
  const splitLocation = pathname.split('/')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { logout } = useAuth0()
  const [menu, setMenu] = useState(Menu)

  const activeClassNames = 'text-violet-600 font-bold'

  const handleDropdownClick = (e: any) => {
    e.preventDefault()
    setIsDropdownOpen(!isDropdownOpen)
  }

  const getMenuItem = (item: MenuItem): React.ReactNode => {
    const url = item.path
    const svgName = item.icon || ''
    const text = item.title
    return (
      <li className="rounded-sm" key={item.path}>
        <Link to={url} className="flex items-center p-2 space-x-3 rounded-md">
          <Svg icon={svgName} />
          <span className={`/${splitLocation[1]}` === url ? activeClassNames : ''}>{text}</span>
        </Link>
      </li>
    )
  }
  const getLogoutItem = (item: MenuItem): React.ReactNode => {
    const svgName = item.icon || ''
    const text = item.title
    return (
      <React.Fragment>
        <li className="rounded-sm">
          <button
            className="flex items-center p-2 space-x-3 rounded-md"
            onClick={() =>
              logout({
                logoutParams: { returnTo: window.location.origin + '/' }
              })
            }>
            <Svg icon={svgName} />
            <span>{text}</span>
          </button>
        </li>
      </React.Fragment>
    )
  }
  const getDropDownButton = (item: MenuItem, expanded: boolean): React.ReactNode => {
    const url = item.path
    const svgName = item.icon || ''
    const text = item.title
    return (
      <React.Fragment key={item.path}>
        {/* dropdown button */}
        <li className="rounded-sm" key={item.path}>
          <Link to={url} className="flex items-center p-2 space-x-3 rounded-md">
            <Svg icon={svgName} />
            <div className="flex items-center gap-1">
              <span className={`/${splitLocation[1]}` === url ? activeClassNames : ''}>{text}</span>
              <div onClick={handleDropdownClick}>
                {expanded ? <Svg icon="dropdownClose" /> : <Svg icon="dropdown" />}
              </div>
            </div>
          </Link>
        </li>
        {/* items */}
        {expanded && (
          <div className="flex flex-col items-start">
            {clients?.map((item, i) => {
              return (
                <Link to={'/client/' + item.id} key={item.id}>
                  <div key={i} className="ml-8 font-bold">
                    {item.name}
                  </div>
                </Link>
              )
            })}
          </div>
        )}
      </React.Fragment>
    )
  }

  useEffect(() => {
    if (isMixedAdmin()) {
      setMenu(MenuForAdmin)
    } else if (isReadOnlyUser()) {
      setMenu(MenuForUser)
    }
  }, [isMixedAdmin, isReadOnlyUser])

  return (
    <div className="flex flex-col h-screen p-3 bg-white shadow w-[15rem] min-w-[15rem]">
      <div className="space-y-3">
        <div className="flex items-center">
          <h2 className="text-xl font-bold">Admin Hub</h2>
        </div>
        <div className="flex-1">
          <ul className="pt-2 pb-4 space-y-1 text-sm">
            {menu.map((item, i) => {
              if (item === Path.clients) {
                return getDropDownButton(item, isDropdownOpen)
              }
              return getMenuItem(item)
            })}
            {getLogoutItem(Path.logout)}
          </ul>
        </div>
      </div>
    </div>
  )
}
