import { useEffect, useRef, useState } from 'react'
import styles from './DynamicContext.module.css'
import { Box, Collapse, TextareaAutosize, Typography } from '@mui/material'

import { init } from '@sentry/react'
// import { set } from 'mongoose'
import { jsonrepair } from 'jsonrepair'
import {
  ContextFactorsResponseDTO,
  PopulateQuestionSpacesDTO,
  PopulateQuestionSpacesResponseDTO,
  ProcessQueryContextDTO,
  ProcessQuestionSpaceAnswerDTO,
  QuestionSpaceAnswerResponseDTO,
  SummariseQueryContextDTO
} from '../apis/EoiAPI.dto'
import { useParams } from 'react-router-dom'

// import { toastError, toastSuccess } from 'utils/toast'
// import { checkRepairParseJson, isJsonTextValid } from 'utils/json'
import { EoiAPI } from '../apis/EoiAPI'
import { checkRepairParseJson } from '../utils/json'
import { toastError, toastSuccess } from '../utils/toast'
import { useDataContext } from '../context/DataProvider'
import { Background, Profile, QueryContext, QuestionSpace } from '../apis/entities/program.entity'
import SidebarLayout from '../components/SidebarLayout'
import { JsonEditor, OnChangeFunction } from 'json-edit-react'

type PageProps = {
  onContinue: () => void
}

const DynamicContext = ({ onContinue }: PageProps) => {
  const { clientCode, programCode } = useParams()

  const { checkProgram, program } = useDataContext()

  //page states
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [briefInputText, setBriefInputText] = useState('')
  const [spacesInputText, setSpacesInputText] = useState('')
  const [profileText, setProfileText] = useState('')
  const [background, setBackground] = useState<Background | any>()
  const [profile, setProfile] = useState<Profile | any>()
  const [promptOutputText, setPromptOutputText] = useState('')

  // const [queryContext, setQueryContext] = useState<QueryContext | undefined>(undefined)

  // const [outputItems, setOutputItems] = useState<QuestionSpace[]>([])
  const [incomingStacks, setIncomingStacks] = useState<QuestionSpace[]>([])
  const [accumulatedStacks, setAccumulatedStacks] = useState<QuestionSpace[]>([])
  const [questionSapces, setQuestionSpaces] = useState<QuestionSpace[]>([])

  useEffect(() => {
    checkProgram(clientCode, programCode)
  }, [checkProgram, clientCode, programCode])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if Alt + S is pressed
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault() // Prevent the default action
        handleClickSave() // Call your save function
      }
    }

    // Add the event listener for keydown
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  //first load
  useEffect(() => {
    //query context
    // setQueryContext(program?.queryContext)
    // const { questionSpaces, profile, ...displayContext } = program?.queryContext || {}
    // const displayContext = { ...program?.queryContext } // Create a shallow copy to avoid mutating the original object
    // delete displayContext.questionSpaces // Remove the property
    // delete displayContext.profile // Remove the property
    // const contextString = JSON.stringify(displayContext, null, 2) // Stringify the modified context
    // setBriefInputText(contextString)

    //question spaces
    setQuestionSpaces(program?.queryContext?.questionSpaces ?? [])

    //profile
    // const profileString = JSON.stringify(program?.queryContext?.profile, null, 2)
    // setProfileText(profileString)

    setBackground(program?.queryContext?.background)

    setProfile(program?.queryContext?.profile)
  }, [program])

  useEffect(() => {
    setSpacesInputText(JSON.stringify(questionSapces, null, 2))
  }, [questionSapces])

  const handleContextTextChange = (value: string) => {
    setBriefInputText(value)
  }

  const handleAnalysisTextChange = (value: string) => {
    setProfileText(value)
  }

  const handleSpacesTextChange = (value: string) => {
    setSpacesInputText(value)
    // const newJsonText = repairJsonText(value)
    // if (isJsonTextValid(newJsonText)) {
    //   const json = JSON.parse(newJsonText)
    //   setQuestionSpaces(json)
    // }
  }

  const handleClickAdd = async () => {
    console.log('click continue')
    // after dimension satisified
    // onContinue()

    await handleAnswer()

    // if (checkAllSpaceSatistified()) {
    //   onContinue()
    // } else {
    // }
  }

  const processStreamChunkCallback = async (
    message: string,
    accumulatedMessage: string,
    isFinished: boolean,
    params: any
  ) => {
    //////// check response ////////
    let json: ContextFactorsResponseDTO
    try {
      const repairedJsonText = jsonrepair(accumulatedMessage)
      json = JSON.parse(repairedJsonText)
      setPromptOutputText(JSON.stringify(json, null, 2))
      const newFactors = json.factors
      if (newFactors) {
        setIncomingStacks(newFactors)
        if (isFinished) {
          setAccumulatedStacks(accumulatedStacks.concat(newFactors))
          setIncomingStacks([])
        }
      }
    } catch (e) {
      //There will be cases cannot be repaired throwing missing colon errors
      // console.error('error:', e)
      return
    }
  }

  const processPopulateQuestionSpacesStreamChunkCallback = async (
    message: string,
    accumulatedMessage: string,
    isFinished: boolean,
    params: any
  ) => {
    //////// check response ////////
    let json: PopulateQuestionSpacesResponseDTO
    try {
      const repairedJsonText = jsonrepair(accumulatedMessage)
      json = JSON.parse(repairedJsonText)
      const questionSpaces = json.areas
      setSpacesInputText(JSON.stringify(questionSpaces, null, 2))
    } catch (e) {
      //There will be cases cannot be repaired throwing missing colon errors
      // console.error('error:', e)
      return
    }
  }

  const processSummaryStreamChunkCallback = async (
    message: string,
    accumulatedMessage: string,
    isFinished: boolean,
    params: any
  ) => {
    //////// check response ////////
    let json: ContextFactorsResponseDTO
    try {
      const repairedJsonText = jsonrepair(accumulatedMessage)
      json = JSON.parse(repairedJsonText)
      setQuestionSpaces(json.factors)
    } catch (e) {
      //There will be cases cannot be repaired throwing missing colon errors
      // console.error('error:', e)
      return
    }
  }

  const handleAnswer = async () => {
    // const questionAnswer = briefInputText
    // if (!questionAnswer) {
    //   return
    // }
    console.log('background', background)

    const dto: ProcessQueryContextDTO = {
      context: {
        background: background,
        questionSpaces: questionSapces,
        profile: profile
      }
    }
    dto.context.questionSpaces = accumulatedStacks

    setIsSubmitting(true)
    await EoiAPI.processQueryContextStream(dto, processStreamChunkCallback, {})
    setIsSubmitting(false)
  }

  const handleSummarise = async () => {
    console.log('click summarise', accumulatedStacks)
    if ((accumulatedStacks?.length ?? 0) === 0) {
      return
    }

    const newContext = {
      background: background,
      questionSpaces: questionSapces,
      profile: profile
    }

    const dto: SummariseQueryContextDTO = {
      context: newContext!,
      factors: accumulatedStacks
    }
    console.log('queryContext:', newContext)

    setIsSubmitting(true)
    await EoiAPI.summariseQueryContextStream(dto, processSummaryStreamChunkCallback, {})
    setIsSubmitting(false)
  }

  const handleClickSave = async () => {
    console.log('click save')
    // after dimension satisified
    // onContinue()

    // const checkedContextJson = checkRepairParseJson(`{ "context": ${briefInputText} }`)
    const checkedBackgroundJson = background // copy is necessary
    const checkedSpacesJson = checkRepairParseJson(spacesInputText)
    // const checkedProfileJson = checkRepairParseJson(profileText)
    const checkedProfileJson = profile

    console.log('checkedBackgroundJson:', checkedBackgroundJson)
    console.log('checkedSpacesJson:', checkedSpacesJson)
    console.log('checkedAnalysisJson:', checkedProfileJson)

    if (checkedBackgroundJson && checkedSpacesJson && checkedProfileJson) {
      // const dto: ProcessQueryContextDTO = checkedContextJson
      let newBackground: Background = checkedBackgroundJson
      let newQuestionSpaces = checkedSpacesJson
      // console.log('newQuestionSpaces:', newQuestionSpaces)

      let newProfile: Profile = checkedProfileJson

      // //filter skip no need assessment
      // newQuestionSpaces = newQuestionSpaces.filter(
      //   (factor: QuestionSpace) => factor.needAssessment === true,
      // )

      //add other fields
      newQuestionSpaces = newQuestionSpaces?.map((factor: QuestionSpace, index: number) => {
        delete factor.importance
        delete factor.risk
        delete factor.needAssessment
        return {
          ...factor,
          id: (index + 1).toString(),
          questionLimit: factor.questionLimit ?? 3,
          suggestedDataPointCount: factor.suggestedDataPointCount ?? 3,
          dimensions: factor.dimensions ?? [],
          questions: (factor.questions?.length ?? 0) === 0 ? [{ id: '0', question: '' }] : factor.questions,
          assess: factor.assess ?? false
        }
      })

      console.log('newQuestionSpaces:', newQuestionSpaces)

      const dto: ProcessQueryContextDTO = {
        context: {}
      }
      dto.context.background = newBackground
      dto.context.questionSpaces = newQuestionSpaces
      dto.context.profile =
        (newProfile.attributes?.length ?? 0) === 0
          ? {
              attributes: [{ id: '0', key: '', name: '', description: '', metric: '' }]
            }
          : newProfile

      try {
        console.log('dto:', dto)
        const response = await EoiAPI.updateProgramQueryContext({
          clientCode: clientCode || '',
          programCode: programCode || '',
          queryContext: dto.context
        })

        setAccumulatedStacks([])
        setPromptOutputText('')
        setQuestionSpaces(newQuestionSpaces)
        setSpacesInputText(JSON.stringify(newQuestionSpaces, null, 2))
        // setProfileText(JSON.stringify(dto.context.profile, null, 2))

        // const displayContext = { ...dto.context } // Create a shallow copy to avoid mutating the original object
        // delete displayContext?.questionSpaces // Remove the property
        // delete displayContext?.profile // Remove the property
        // const contextString = JSON.stringify(displayContext, null, 2) // Stringify the modified context
        // setBriefInputText(contextString)
        checkProgram(clientCode, programCode, true)

        toastSuccess('Saved successfully', 3000)
      } catch (e) {
        toastError('Failed to save')
      }
    } else {
      toastError('Invalid data format')
    }
  }

  const handlePopulate = async () => {
    console.log('handle populate')

    const dto: PopulateQuestionSpacesDTO = {
      context: {
        background: background,
        questionSpaces: questionSapces,
        profile: profile
      }!
    }
    await EoiAPI.populateQuestionSpacesStream(dto, processPopulateQuestionSpacesStreamChunkCallback, {})
  }

  const handleProfileChange = (newData: any) => {
    if (program?.queryContext?.profile) {
      program.queryContext.profile = newData as Profile // Ensure newData matches Profile type
    }

    setProfile(newData as Profile) // Update local state
    return newData // Return the new data as expected by the editor
  }

  const summaryStack = accumulatedStacks.concat(incomingStacks)

  // console.log('summaryStack:', summaryStack)
  return (
    <>
      <SidebarLayout>
        <div
          style={{
            position: 'absolute',
            // right: '10px',
            bottom: '0px',
            zIndex: 1,
            backgroundColor: '#ffffffaa',
            width: '100%',
            backdropFilter: 'blur(10px)',
            paddingTop: '16px',
            paddingBottom: '16px'
          }}>
          <button
            style={{ fontSize: '18px', fontWeight: 'bold' }}
            className={`${styles.submitbutton} ${isSubmitting ? styles.disabled : ''}`}
            onClick={handleClickSave}
            disabled={isSubmitting}>
            Save
          </button>
        </div>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 style={{ textAlign: 'center', marginBottom: '28px', fontSize: '20px', fontWeight: 'bold' }}>
              {program?.name}
            </h1>
            {/* first load empty */}
            <div style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '8px' }}>Query context (JSON)</div>
            {/* <TextareaAutosize
              disabled={isSubmitting}
              className={`${styles.textarea}`}
              placeholder={''}
              minRows={3}
              onChange={e => {
                handleContextTextChange(e.target.value)
              }}
              value={briefInputText}
              maxLength={4000}
            /> */}
            {background && (
              <JsonEditor
                rootName="Background"
                minWidth={'100%'}
                maxWidth={'100%'}
                // restrictDrag={false}
                showArrayIndices={false}
                showCollectionCount={'when-closed'}
                restrictDelete
                enableClipboard={false}
                restrictTypeSelection
                data={background}
                setData={setBackground}
                stringTruncate={10000}
              />
            )}

            <div
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                className={`${styles.submitbutton} ${isSubmitting ? styles.disabled : ''}`}
                onClick={handleClickAdd}
                disabled={isSubmitting}>
                Add
              </button>
            </div>

            {promptOutputText && (
              <div style={{ marginBottom: '20px' }}>
                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>Output:</div>
                <div>
                  <pre
                    style={{
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}>
                    {promptOutputText}
                  </pre>
                </div>
              </div>
            )}

            {summaryStack?.length > 0 && (
              <div style={{ marginBottom: '20px' }}>
                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>Summary:</div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '20px',
                    rowGap: '10px'
                  }}>
                  {summaryStack?.map((item, index) => (
                    <div key={index}>
                      {index + 1}. {item.name}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <button
                style={{ marginTop: '20px', marginBottom: '20px' }}
                className={`${styles.submitbutton} ${isSubmitting ? styles.disabled : ''}`}
                onClick={handleSummarise}
                disabled={isSubmitting}>
                Refine
              </button>
            </div>

            {questionSapces && questionSapces.length > 0 && (
              <div>
                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                  Key areas to discover about the learner (JSON):
                </div>
                <TextareaAutosize
                  disabled={isSubmitting}
                  className={`${styles.textarea}`}
                  placeholder={''}
                  minRows={3}
                  onChange={e => {
                    handleSpacesTextChange(e.target.value)
                  }}
                  value={spacesInputText}
                />

                {/* <div>
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {JSON.stringify(questionSapces, null, 2)}
              </pre>
            </div> */}
              </div>
            )}

            <div
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <button
                style={{ width: '160px', marginTop: '20px', marginBottom: '20px' }}
                className={`${styles.submitbutton} ${isSubmitting ? styles.disabled : ''}`}
                onClick={handlePopulate}
                disabled={isSubmitting}>
                Add questions
              </button>
            </div>

            <div style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '8px' }}>Profile analysis (JSON)</div>
            {/* <TextareaAutosize
              disabled={isSubmitting}
              className={`${styles.textarea}`}
              placeholder={''}
              minRows={3}
              onChange={e => {
                handleAnalysisTextChange(e.target.value)
              }}
              value={profileText}
              maxLength={4000}
            /> */}
            {profile && (
              <JsonEditor
                rootName="Profile"
                minWidth={'100%'}
                maxWidth={'1200px'}
                // restrictDrag={false}
                showArrayIndices={false}
                showCollectionCount={'when-closed'}
                restrictDelete
                enableClipboard={false}
                restrictTypeSelection
                data={profile}
                setData={setProfile}
                stringTruncate={10000}
              />
            )}

            <div
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center'
              }}></div>
          </div>
        </div>
      </SidebarLayout>
    </>
  )
}

export default DynamicContext
