import { LearnerReport } from '../apis/entities/learnerReport.entity'
import closeSvg from '../images/close2.svg'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Box from '@mui/material/Box'
import { camelToSentence } from '../utils/stringUtils'
import { EoiAPI } from '../apis/EoiAPI'
import { useState } from 'react'
import LoadingIndicator from '../components/LoadingIndicator'

interface Props {
  report: LearnerReport
  onClose: () => void
  onTriggerReload?: () => void
}

export default function ModalEoiAnswerDetails({ report, onClose, onTriggerReload }: Props): JSX.Element {
  const [isloading, setIsLoading] = useState(false)
  const handleAnalyse = async (programId: string | undefined, email: string | undefined) => {
    if (!programId || !email || isloading) return
    setIsLoading(true)
    console.log('Reanalyse', programId, email)
    const result = await EoiAPI.updateProfile(programId!, email!)
    console.log('result', result)
    setIsLoading(false)
    onTriggerReload?.()
  }

  console.log('ModalAnswerDetails', report)
  if ((report.questions?.length ?? 0) > 0) {
    return (
      <div
        className="w-full h-full bg-[#35353BAA] z-[1001] flex items-center justify-center"
        onClick={
          // click outside the widget to close
          e => {
            // if (e.target === e.currentTarget) {
            // onClose()
            // }
          }
        }>
        <div className="h-[90%] w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px]">
          <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
          <div className="flex flex-col items-center gap-1 pb-8 px-16 overflow-auto">
            {report.questions?.map((question, index) => (
              <div key={index} className="flex flex-col gap-1 w-full text-left font-roboto">
                <div className="text-[16px] font-[700] text-[#BD69FE]">Question {index + 1}</div>
                <div className="text-[16px] font-[400] text-[#121419]">{question.question}</div>
                <div className="text-[16px] font-[700]">Answer</div>
                <Box
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    color: '#121419'
                  }}>
                  <TextareaAutosize
                    minRows={1}
                    maxRows={2}
                    value={question.answer}
                    style={{
                      width: '100%',
                      border: 'none',
                      outline: 'none',
                      resize: 'none',
                      fontWeight: 600,
                      fontStyle: 'italic'
                    }}
                    disabled={true}
                  />
                </Box>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  } else if ((report.questionSpaces?.length ?? 0) > 0) {
    const allQuestions = report.questionSpaces!.flatMap(qs => qs.questions ?? []).filter(q => q.answer)
    return (
      <div
        className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center"
        onClick={
          // click outside the widget to close
          e => {
            if (e.target === e.currentTarget) {
              // onClose()
            }
          }
        }>
        <div className="h-[90%] w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px]">
          <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
          <div className="flex flex-col items-center gap-1 pb-8 px-16"></div>

          <div className="flex flex-col items-center gap-1 pb-8 px-16 overflow-auto">
            <div className="text-lg font-[700] text-left w-full mb-8">
              {report.firstName + ' ' + (report.lastName ?? '')}
            </div>
            <div className="flex flex-row gap-4 w-full text-left font-roboto mb-4 font-[700] ">
              <div className="text-lg">Profile</div>

              {!isloading && (
                <button
                  onClick={() => handleAnalyse(report.program, report.email)}
                  style={{ color: '#BD69FE', border: '1px #BD69FE solid', borderRadius: '20px', padding: '1px 8px' }}>
                  Re-analyse
                </button>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                alignItems: 'start',
                justifyContent: 'start',
                width: '100%'
              }}>
              {isloading && (
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%'
                  }}>
                  <LoadingIndicator color="#BD69FEaa" />
                </div>
              )}
              <div>
                {report.attributes?.map((attribute, index) => (
                  <div key={index} className="flex flex-col gap-1 w-full text-left font-roboto mb-4">
                    <div className="text-[16px] font-[700] text-[#BD69FE]">{`${camelToSentence(attribute.key)}: ${
                      attribute.value
                    }`}</div>
                    <div className="text-[16px] font-[700] ">{`${attribute.reason}`}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1 w-full text-left font-roboto mb-12 font-[700]"></div>
            <div className="flex flex-col gap-1 w-full text-left font-roboto mb-4 font-[700] text-lg">
              Conversations
            </div>
            {allQuestions?.map((question, index) => (
              <div key={index} className="flex flex-col gap-1 w-full text-left font-roboto mb-4">
                {/* <div className="text-[16px] font-[700] text-[#BD69FE]">Question {index + 1}</div> */}
                <div className="text-[16px] font-[400] text-[#121419]">{question.question}</div>
                {/* <div className="text-[16px] font-[700]">Answer</div> */}
                <Box
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    color: '#121419',
                    backgroundColor: '#f4f4f4',
                    padding: '6px 12px',
                    display: 'flex'
                  }}>
                  <TextareaAutosize
                    minRows={1}
                    maxRows={2}
                    value={question.answer}
                    style={{
                      width: '100%',
                      border: 'none',
                      outline: 'none',
                      resize: 'none',
                      fontWeight: 600,
                      fontStyle: 'italic'
                    }}
                    disabled={true}
                  />
                </Box>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[1001] flex items-center justify-center">
      <div className="h-[90%] w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <p>No data</p>
      </div>
    </div>
  )
}
