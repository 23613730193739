import { LearnerReport } from '../apis/entities/learnerReport.entity'
import closeSvg from '../images/close2.svg'

interface Props {
  onClose: () => void
  report: LearnerReport
}

export default function ModalEoiHistory({ onClose, report }: Props): JSX.Element {
  const isConfirmEmail = (data: any): boolean => {
    return (
      data.learnerConfirmation !== undefined ||
      data.managerConfirmation !== undefined ||
      data.managerConsent !== undefined
    )
  }

  const isValidData = (data: any): boolean => {
    // skip the application created status log
    if (data.submissionStatus && data.submissionStatus === 'created') {
      return false
    }
    // check has learnerConfirmation or managerConfirmation or managerConsent
    if (isConfirmEmail(data)) {
      return true
    }
    // has applicationStatus or emailType
    return data.applicationStatus || data.emailType
  }

  // clone the logs array to avoid mutating the original array
  // add a dummy log for the application created status
  const logs = [...report.logs].filter(log => isValidData(log.data))

  const convertDate = (date: Date) => {
    const sydneyTime = new Date(date.toLocaleString('en-AU', { timeZone: 'Australia/Sydney' }))
    // must be en-US to get the correct timezone
    const nowSydney = new Date(new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' }))

    const isToday =
      sydneyTime.getFullYear() === nowSydney.getFullYear() &&
      sydneyTime.getMonth() === nowSydney.getMonth() &&
      sydneyTime.getDate() === nowSydney.getDate()

    // console.log('sydneyTime', sydneyTime)
    // console.log('nowSydney', nowSydney)
    // console.log('isToday', isToday)

    // Enforce 24-hour format explicitly
    const timeString = sydneyTime.toLocaleTimeString('en-AU', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Ensures 24-hour format
      timeZone: 'Australia/Sydney'
    })

    if (isToday) {
      return `${timeString} Today`
    }

    const dateString = sydneyTime.toLocaleDateString('en-AU', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'Australia/Sydney'
    })

    return `${timeString} ${dateString}`
  }

  const getStatusMessage = (applicationStatus: string, submissionStatus: string, index: number) => {
    // last log is the application created status
    if (index === logs.length - 1) {
      return `Application ${applicationStatus.toLowerCase()}`
    } else if (submissionStatus === 'edited') {
      return `Application ${submissionStatus}`
    } else {
      return `Status changed to ${applicationStatus.toLowerCase()}`
    }
  }

  const getEmailMessage = (type: string, email?: string, managerEmail?: string) => {
    if (type.includes('learner')) {
      return `Email sent to ${email || report.email}`
    } else if (type.includes('manager')) {
      return `Email sent to ${managerEmail || report.managerEmail}`
    } else {
      return `Email sent`
    }
  }

  const getConfirmEmailMessage = (data: any) => {
    if (data.learnerConfirmation !== undefined) {
      return data.learnerConfirmation ? 'Learner confirmed' : 'Learner declined'
    } else if (data.managerConfirmation !== undefined) {
      return data.managerConfirmation ? 'Manager confirmed' : 'Manager declined'
    } else if (data.managerConsent !== undefined) {
      return data.managerConsent ? 'Manager consented' : 'Manager declined consent'
    }
    return 'unknown'
  }

  const renderLog = (
    log: {
      data: {
        applicationStatus: string
        cohort: string
        emailType: string
        submissionStatus: string
        email: string
        managerEmail: string
        learnerConfirmation: boolean
        managerConfirmation: boolean
        managerConsent: boolean
        createdBy: string
        action: string
      }
      createdAt: Date
    },
    index: number
  ) => {
    return (
      <div className="text-[14px] font-[500] font-inter text-left hover:bg-primary w-full p-[4px] hover:text-white group">
        <span className="text-[#121419] group-hover:text-white">
          {log.data.applicationStatus && getStatusMessage(log.data.applicationStatus, log.data.submissionStatus, index)}
          {log.data.cohort && ` (${log.data.cohort})`}
          {isConfirmEmail(log.data) && getConfirmEmailMessage(log.data)}
          {log.data.emailType && getEmailMessage(log.data.emailType, log.data.email, log.data.managerEmail)}
          {/* {log.data && JSON.stringify(log.data)} */}
        </span>
        {' at '}
        <span className="text-[#BA61FF] group-hover:text-white">{convertDate(log.createdAt)}</span>
      </div>
    )
  }

  return (
    <div
      className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center"
      onClick={
        // click outside the widget to close
        e => {
          if (e.target === e.currentTarget) {
            onClose()
          }
        }
      }>
      <div className="h-auto max-h-[372px] w-[600px] flex flex-col bg-white shadow-xl rounded-[12px] gap-[12px] p-[12px] pb-[36px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <div className="flex flex-col items-start justify-start px-[24px]">
          {logs.length <= 0 && (
            <div className="text-[14px] font-[500] font-inter text-[#121419]">No history available</div>
          )}
          {logs.length > 0 && (
            <div className="flex flex-row gap-[12px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_2957_35542)">
                  <path
                    d="M10 18.3333C11.0945 18.3347 12.1786 18.1197 13.1898 17.7009C14.201 17.282 15.1195 16.6674 15.8925 15.8925C16.6674 15.1195 17.282 14.201 17.7009 13.1898C18.1197 12.1786 18.3347 11.0945 18.3333 10C18.3347 8.90545 18.1197 7.82142 17.7009 6.8102C17.282 5.79898 16.6674 4.88048 15.8925 4.1075C15.1195 3.33256 14.201 2.718 13.1898 2.29913C12.1786 1.88025 11.0945 1.66531 10 1.66666C8.90545 1.66531 7.82142 1.88025 6.8102 2.29913C5.79898 2.718 4.88048 3.33256 4.1075 4.1075C3.33256 4.88048 2.718 5.79898 2.29913 6.8102C1.88025 7.82142 1.66531 8.90545 1.66666 10C1.66531 11.0945 1.88025 12.1786 2.29913 13.1898C2.718 14.201 3.33256 15.1195 4.1075 15.8925C4.88048 16.6674 5.79898 17.282 6.8102 17.7009C7.82142 18.1197 8.90545 18.3347 10 18.3333Z"
                    stroke="#BD69FE"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5598 14.9348C10.4113 15.0833 10.21 15.1667 10 15.1667C9.79005 15.1667 9.58868 15.0833 9.44022 14.9348C9.29175 14.7863 9.20834 14.585 9.20834 14.375C9.20834 14.165 9.29175 13.9637 9.44022 13.8152C9.58868 13.6667 9.79005 13.5833 10 13.5833C10.21 13.5833 10.4113 13.6667 10.5598 13.8152C10.7083 13.9637 10.7917 14.165 10.7917 14.375C10.7917 14.585 10.7083 14.7863 10.5598 14.9348Z"
                    fill="black"
                    stroke="#BD69FE"
                    strokeWidth="0.5"
                  />
                  <path
                    d="M10 5V11.6667"
                    stroke="#BD69FE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2957_35542">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="text-[14px] font-[700] font-inter text-[#000]">
                All timestamps are Sydney time, most recent shown first
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-start justify-start px-[24px] gap-[8px] overflow-auto table-scrollbar table-scrollbar-grey">
          {logs.map((log, index) => (
            <div key={index} className="flex flex-col items-center justify-center gap-[12px] w-full">
              {renderLog(log, index)}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
