import { useCallback, useRef, forwardRef, useImperativeHandle } from 'react'
import './SearchInput.css'

interface SearchInputProps {
  onInputChanged: (value: string) => void
  onEnter: () => void
  isSearching: boolean
  placeholder?: string
}

const SearchInput = forwardRef((props: SearchInputProps, ref) => {
  const refInput = useRef<HTMLInputElement>(null)

  const onInput = useCallback(
    async (e: any) => {
      const value = refInput.current?.value || ''
      props.onInputChanged(value)
    },
    [props]
  )

  const onKeyUp = useCallback(
    async (e: any) => {
      if (e.key === 'Enter') {
        // console.log('onEnter')
        // const value = refInput.current!.value
        // if (value.length > 0) {
        props.onEnter()
        // }
      }
    },
    [props]
  )

  // Use useImperativeHandle to expose a method to the parent
  useImperativeHandle(ref, () => ({
    clear: () => {
      if (refInput.current) {
        refInput.current.value = ''
        props.onInputChanged('')
      }
    }
  }))

  return (
    <div className="flex flex-col items-center justify-items-center">
      <div className="w-full max-w-lg">
        <div className="relative">
          <label className="w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#B5B5BE"
              className="pointer-events-none absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform">
              <path
                fillRule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                clipRule="evenodd"
              />
            </svg>
            <input
              data-testid="input-search"
              ref={refInput}
              type="search"
              className="inputSearchWithIcon"
              placeholder={props.placeholder || 'Search here'}
              aria-label="Search"
              aria-describedby="btn-search"
              onInput={onInput}
              onKeyUp={onKeyUp}
            />
          </label>
        </div>
      </div>
    </div>
  )
})

export default SearchInput
