import { toast } from 'react-toastify'

export const toastSuccess = (message: string, autoClose?: number) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: autoClose || 3500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  })
}

export const toastError = (message: string) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  })
}

export const toastInfo = (message: string, autoClose?: number) => {
  toast.info(message, {
    position: 'top-center',
    autoClose: autoClose || 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    style: {
      whiteSpace: 'pre-line'
    }
  })
}
