import { useEffect, useState } from 'react'
import { LearnerReport } from '../apis/entities/learnerReport.entity'
import closeSvg from '../images/close2.svg'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import PlusIcon from '@mui/icons-material/Add'
import MinusIcon from '@mui/icons-material/Remove'
import { Box, styled } from '@mui/material'
import Checkbox from '../components/Checkbox'
import DropDownMenu, { DropDownItem } from '../components/DropDownMenu'
import ReactLoading from 'react-loading'
import React from 'react'
import { toastError, toastInfo, toastSuccess } from '../utils/toast'
import { EoiAPI } from '../apis/EoiAPI'
import Emitter from '../core/emitter'
import { Switch } from '../components/Switch'

interface Props {
  reports: Array<LearnerReport>
  preCheckedIds: Array<string>
  cohorts: Array<string>
  isInvite: boolean // true for invite, false for reject
  onClose: () => void
}

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none'
        },
        '.expandIconWrapper': {
          display: 'none'
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block'
        }
      }}>
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapsIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  )
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    minWidth: '150px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&::before': {
      display: 'none'
    },
    '& .Mui-expanded.MuiAccordionSummary-root': {
      border: '1px solid rgba(0, 0, 0, 1)'
    }
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<CustomExpandIcon />} {...props} />
))(({ theme }) => ({
  fontWeight: 700,
  fontSize: '16px',
  fontFamily: 'Roboto',

  minHeight: '25px',
  '& .MuiAccordionSummary-content': {
    margin: '4px 0'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 1)',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Roboto',
  textAlign: 'left'
}))

export default function ModalEoiInviteOrReject({
  reports,
  preCheckedIds,
  cohorts,
  isInvite,
  onClose
}: Props): JSX.Element {
  const [groupedReports, setGroupedReports] = useState<Array<Array<LearnerReport>>>([])
  const [checkedIds, setCheckedIds] = useState<Array<string>>(preCheckedIds)
  const refPreSelectedCohort = React.useRef<string>(cohorts.length > 0 ? cohorts[0] : '')
  const [showAll, setShowAll] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const divRef = React.createRef<HTMLDivElement>()

  const onSave = async () => {
    try {
      if (checkedIds.length === 0) {
        toastInfo('Please select at least one candidate')
        return
      }

      setIsSaving(true)
      if (isInvite) {
        // invite
        await EoiAPI.inviteLearnersToCohort(checkedIds, refPreSelectedCohort.current)
        toastSuccess('Invitation sent')
      } else {
        // reject
        await EoiAPI.rejectLearnersFromCohort(checkedIds)
        toastSuccess('Candidates rejected')
      }
      Emitter.emit('OnSucceedInviteOrRejectLearners', null)
    } catch (error) {
      console.error(error)
      toastError('Failed to save')
    } finally {
      setIsSaving(false)
    }
  }

  const toggleCheckbox = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedIds([...checkedIds, id])
    } else {
      setCheckedIds(checkedIds.filter(_id => _id !== id))
    }
  }

  const onChangeSwitch = (checked: boolean) => {
    setShowAll(!checked)
  }

  const getCheckedCountInGroup = (group: LearnerReport[]) => {
    return group.filter(report => checkedIds.includes(report._id!)).length
  }

  useEffect(() => {
    // filter out the reports that are not in the checkedIds
    let filteredReports: Array<LearnerReport> = []
    if (showAll) {
      filteredReports = reports
    } else {
      filteredReports = reports.filter(report => checkedIds.includes(report._id!))
    }
    // group by business unit
    const grouped = filteredReports.reduce((acc, report) => {
      const key = report.businessUnit || 'No Business Unit'
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(report)
      return acc
    }, {} as Record<string, Array<LearnerReport>>)

    const sortedKeys = Object.keys(grouped).sort()
    setGroupedReports(sortedKeys.map(key => grouped[key]))
  }, [checkedIds, reports, showAll])

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div className="h-[90%] w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <div className="flex flex-col w-full h-full items-start pb-8 px-16 overflow-auto" ref={divRef}>
          <div className="flex flex-row w-full">
            {isInvite ? (
              <p className="text-[16px] font-bold font-roboto">
                You have selected the following <span className="text-primary">{checkedIds.length}</span> candidate(s)
              </p>
            ) : (
              <p className="text-[16px] font-bold font-roboto text-[#FB0505]">
                This will reject the applications for the following{' '}
                <span className="text-primary">{checkedIds.length}</span> candidates - are you sure you want to
                continue?
              </p>
            )}
            <div className="grow" />
            <div className="w-auto">
              <Switch
                checked={!showAll}
                onChange={onChangeSwitch}
                checkedText="Show selected"
                unCheckedText="Show all"
              />
            </div>
          </div>

          <div className="my-[12px] w-full">
            {groupedReports.map((group, index) => (
              <Accordion key={index} className="w-full">
                <AccordionSummary>
                  <div className="flex flex-row w-full">
                    <p>{group[0].businessUnit || 'No Business Unit'}</p>
                    <div className="grow" />
                    <span className="text-primary pr-[20px]">({getCheckedCountInGroup(group)})</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2">
                    {group.map((report, index) => (
                      <div key={index} className="flex flex-row gap-1">
                        <Checkbox
                          id={report._id!}
                          checked={checkedIds.includes(report._id!)}
                          onChange={checked => {
                            toggleCheckbox(report._id!, checked)
                          }}
                        />
                        <div className="flex items-center relative group max-w-[100px] sm:max-w-[30px] md:max-w-[40px] lg:max-w-[70px] xl:max-w-[90px] 2xl:max-w-[100px]">
                          <span
                            className="truncate block cursor-pointer"
                            onClick={() => {
                              // toggle the checkbox
                              const checked = checkedIds.includes(report._id!)
                              toggleCheckbox(report._id!, !checked)
                            }}>
                            {report.firstName} {report.lastName}
                          </span>
                          <div className="absolute left-0 bottom-full mb-1 ml-[-6px] hidden w-max max-w-xs px-[6px] py-[3px] text-[14px] text-black bg-white rounded-lg shadow-lg border border-[#6B69C1] group-hover:block">
                            {report.firstName} {report.lastName}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          {isInvite && (
            <div className="flex flex-col gap-[12px] items-start w-full">
              <p className="text-[16px] font-bold">Choose the cohort(s) you’d like to invite to</p>
              <DropDownMenu
                items={
                  cohorts.map(cohort => ({
                    id: cohort,
                    name: cohort,
                    value: cohort
                  })) as Array<DropDownItem>
                }
                onSelected={function (item: DropDownItem): void {
                  // console.log('selected', item)
                  refPreSelectedCohort.current = item.value
                }}
                onMouseOver={() => {
                  // scroll to bottom with animation
                  const parentDiv = divRef.current
                  if (parentDiv) {
                    parentDiv.scrollTo({
                      top: parentDiv.scrollHeight,
                      behavior: 'smooth'
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row justify-end px-16">
          <button className="button-primary" onClick={onSave} disabled={isSaving}>
            {isSaving ? (
              <ReactLoading type={'spinningBubbles'} color={'#eeeeee'} height={'16px'} width={'16px'} />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
