import ReactLoading from 'react-loading'
import closeSvg from '../images/close2.svg'
import DropDownMenu, { DropDownItem } from '../components/DropDownMenu'
import { useState } from 'react'
import { ApplicationStatus } from '../apis/entities/learnerReport.entity'
import { toastInfo } from '../utils/toast'

const defaultCohortLabel: DropDownItem = {
  id: '0',
  name: 'Select the cohort',
  value: undefined,
  isLabel: true
}

interface Props {
  data: { applicationStatus: string }
  cohorts: string[]
  isLoading: boolean
  onClose: (confirm: boolean, cohort?: string) => void
}

export default function ModalEoiConfirmation({ data, cohorts, isLoading, onClose }: Props): JSX.Element {
  const [selectedCohort, setSelectedCohort] = useState<string>('')
  const cohortsWithLabel = [defaultCohortLabel, ...cohorts.map(cohort => ({ id: cohort, name: cohort, value: cohort }))]

  const getStatusText = (status: string) => {
    switch (status) {
      case ApplicationStatus.Invited:
        return 'invite'
      case ApplicationStatus.Rejected:
        return 'reject'
      default:
        return ''
    }
  }

  // increase the height based on the number of cohorts, 50px for each cohort
  const height = cohorts.length > 0 ? cohorts.length * 50 : 0

  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div className="h-auto w-auto flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => {
            onClose(false)
          }}
        />
        <div className="flex flex-col items-center justify-center px-8 gap-[12px]">
          <p className="text-[16px] font-bold text-left font-roboto w-[100%]">
            Are you sure you want to{' '}
            <span className="font-[700] font-roboto text-[16px] text-[#BD69FE]">
              {getStatusText(data.applicationStatus)}
            </span>{' '}
            this candidate to the cohort and send them an email?
          </p>
          {data.applicationStatus === ApplicationStatus.Invited && (
            <div
              style={{
                width: '90%',
                height: `${height}px`
              }}>
              <DropDownMenu
                items={cohortsWithLabel}
                style={{
                  justifyItems: 'stretch',
                  fontSize: '16px',
                  fontWeight: '600',
                  textAlign: 'left',
                  labelColor: '#787676'
                }}
                onSelected={function (item: DropDownItem): void {
                  setSelectedCohort(item.value)
                }}
              />
            </div>
          )}
        </div>
        <div className="grow" />
        <div className="flex flex-row justify-center items-center px-8 gap-8">
          <button
            className="button-primary"
            onClick={() => {
              // if the status is invited, check if the cohort is selected
              if (data.applicationStatus === ApplicationStatus.Invited && selectedCohort === '') {
                toastInfo('Please select a cohort')
                return
              }
              onClose(true, selectedCohort)
            }}>
            {isLoading ? (
              <ReactLoading type={'spinningBubbles'} color={'#eeeeee'} height={'16px'} width={'16px'} />
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
