import { Tooltip } from 'react-tooltip'
import closeSvg from '../images/close2.svg'
import { tooltipStyle } from '../utils/TableUtils'

interface Props {
  onClose: () => void
  onSendInvitationReminders: () => void
  onSendUpdateReminders: () => void
}

export default function ModalEoiReminder({
  onClose,
  onSendInvitationReminders,
  onSendUpdateReminders
}: Props): JSX.Element {
  return (
    <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
      <div className="h-auto w-[378px] flex flex-col bg-white shadow-xl rounded-[12px] gap-[12px] p-[12px] pb-[36px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <div className="flex flex-col items-center justify-center px-8 gap-[24px]">
          <button
            className="button-secondary-v2 w-[280px] h-[48px]"
            onClick={onSendInvitationReminders}
            data-tooltip-id="tooltip-send-invitation-reminders">
            Send invitation reminders
          </button>
          <button
            className="button-primary-v2 w-[280px] h-[48px]"
            onClick={onSendUpdateReminders}
            data-tooltip-id="tooltip-send-update-reminders">
            Send update reminders
          </button>
        </div>
      </div>
      <Tooltip
        id="tooltip-send-invitation-reminders"
        key="tooltip-send-invitation-reminders"
        className="mentem-tooltip !opacity-100"
        style={{
          ...tooltipStyle,
          height: 'auto',
          transform: 'translate(50%, 40%)',
          textAlign: 'left'
        }}
        place="top"
        noArrow={true}>
        <span>
          Click this button to send reminders to learners and
          <br />
          managers who have not yet responded to requests.
        </span>
      </Tooltip>
      <Tooltip
        id="tooltip-send-update-reminders"
        key="tooltip-send-update-reminders"
        className="mentem-tooltip !opacity-100"
        style={{
          ...tooltipStyle,
          height: 'auto',
          transform: 'translate(50%, 40%)',
          textAlign: 'left'
        }}
        place="top"
        noArrow={true}>
        <span>
          Click this button to send reminders to encourage
          <br />
          candidates in ‘Applied’ or ‘Reapplied’ status to update their EOI
        </span>
      </Tooltip>
    </div>
  )
}
