import { jsonrepair } from 'jsonrepair'

const deepCleanObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    // Recursively clean array elements
    return obj.map(deepCleanObject)
  } else if (typeof obj === 'object' && obj !== null) {
    // Recursively clean object properties
    return Object.keys(obj)
      .filter((key) => key !== '') // Remove keys that are empty
      .reduce((cleanedObj: any, key: string) => {
        cleanedObj[key] = deepCleanObject(obj[key]) // Recursively clean nested objects
        return cleanedObj
      }, {})
  }
  // Return the value if it's not an array or object
  return obj
}

export const deepCompareStructure = (obj1: any, obj2: any): boolean => {
  const cleanedObj1 = deepCleanObject(obj1)
  const cleanedObj2 = deepCleanObject(obj2)

  if (typeof cleanedObj1 === 'object' && typeof cleanedObj2 === 'object') {
    if (cleanedObj1 === null || cleanedObj2 === null) {
      return cleanedObj1 === cleanedObj2
    }

    const isArray1 = Array.isArray(cleanedObj1)
    const isArray2 = Array.isArray(cleanedObj2)

    if (isArray1 !== isArray2) return false

    if (isArray1 && isArray2) {
      if (cleanedObj1.length !== cleanedObj2.length) return false
      return cleanedObj1.every((item: any, index: number) =>
        deepCompareStructure(item, cleanedObj2[index]),
      )
    } else {
      const keys1 = Object.keys(cleanedObj1)
      const keys2 = Object.keys(cleanedObj2)

      if (keys1.length !== keys2.length) return false

      return keys1.every(
        (key) =>
          keys2.includes(key) &&
          deepCompareStructure(cleanedObj1[key], cleanedObj2[key]),
      )
    }
  } else {
    return typeof cleanedObj1 === typeof cleanedObj2
  }
}

export const repairJson = (text: string): any => {
  try {
    const repairedJsonText = jsonrepair(text)
    const json = JSON.parse(repairedJsonText)
    return json
  } catch (e) {
    return undefined
  }
}

export const isJsonTextValid = (jsonString: string): boolean => {
  try {
    JSON.parse(jsonString)
    return true // JSON is valid
  } catch (error) {
    console.error('Invalid JSON:', error)
    return false // JSON is invalid
  }
}

export const checkRepairParseJson = (jsonString: string): any => {
  if (isJsonTextValid(jsonString)) {
    console.log('original JSON is valid')
    return JSON.parse(jsonString)
  } else {
    const repairedJson = repairJson(jsonString)
    console.log('repaired JSON is valid')
    return repairedJson
  }
}
