import { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { Organisation } from '../apis/entities/organisation.entity'
import { useAuth0 } from '@auth0/auth0-react'
import { AdminAPI } from '../apis/AdminAPI'
import { Program } from '../apis/entities/program.entity'
import { GetProgramDto } from '../apis/EoiAPI.dto'
import { EoiAPI } from '../apis/EoiAPI'
import { useNavigate } from 'react-router-dom'
import { defaultSlug } from '../apis/entities/submission.entity'

interface DataContextProps {
  clients: Organisation[] | undefined | null

  program: Program | undefined
  setProgram: (program: Program | undefined) => void

  setClientCode: (clientCode: string | undefined) => void
  setProgramCode: (programCode: string | undefined) => void

  checkProgram: (clientCode: string | undefined, programCode: string | undefined, forceReload?: boolean) => void
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = (): DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}
export const DataProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const [clients, setClients] = useState<Organisation[] | undefined | null>(undefined)
  const { isAuthenticated } = useAuth0()

  const [program, setProgram] = useState<Program | undefined>(undefined)

  const [clientCode, setClientCode] = useState<string | undefined>(undefined)
  const [programCode, setProgramCode] = useState<string | undefined>(undefined)

  const checkProgram = async (
    _clientCode: string | undefined,
    _programCode: string | undefined,
    forceReload?: boolean
  ) => {
    //check needs reload
    if (!forceReload && programCode === _programCode && clientCode === _clientCode) {
      return
    }
    //prevent reload
    setClientCode(_clientCode)
    setProgramCode(_programCode)

    const dto: GetProgramDto = {
      clientCode: _clientCode || '',
      programCode: _programCode || ''
    }

    const result = await EoiAPI.getProgramByCode(dto)
    console.log('getProgram:', result)
    if (result) {
      setProgram(result)
    } else {
      const currentSlug = `${_clientCode}/${_programCode}`
      if (currentSlug !== defaultSlug) {
        navigate(`/`)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await AdminAPI.getClients()
      setClients(data)
    }
    if (isAuthenticated) {
      fetchData()
    }
  }, [isAuthenticated, setClients])

  const providerValue = {
    clients,
    program,
    setProgram,
    setClientCode,
    setProgramCode,
    checkProgram
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
