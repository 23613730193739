export interface Question {
  id: string
  question: string | undefined
  placeholder?: string | undefined
  answer: string | undefined
}

export interface Submission {
  _id?: string
  program: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string
  managerEmail?: string
  businessUnit?: string
  roleType?: string
  role?: string
  location?: string
  linkedIn?: string
  tncAgreeAt?: Date
  questions?: Question[]
  dates?: string[]
  criteriaJob?: string
  returnURL?: string
  createdAt?: Date
  updatedAt?: Date

  // rla
  rlaChapterLeadDiscussionConfirmationAt?: Date
  rlaTimeAndLeaveCommitmentConfirmationAt?: Date
  otherLocation?: string

  // generic fields
  jobTitle?: string
  organisation?: string
  phoneNumber?: string
  numberOfLearners?: string
  preferredCourseDate?: string

  // for frontend
  cognifyScore?: number
}

export const defaultSlug: string = ''
